import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

import { getFileIcon } from '../../../utils';

const FileCard = ({ file, progress, uploadError, isUploadMode, removeItem }) => {
	let ext = file?.name.split('.').filter((el) => el);
	ext = ext.length >= 2 ? ext[ext.length - 1] : '';

	return (
		<Box mt={'15px'}>
			<Card variant="outlined">
				<CardContent sx={{ paddingBottom: '7px !important' }}>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Box display="flex" alignItems="center">
							<Logo>
								<div
									style={{
										width: '100%',
										height: '100%',
										position: 'absolute',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										color: '#fff',
										fontWeight: 'bold',
									}}
								>
									<div>{`${ext || '?'}`}</div>
								</div>
								<Img src={getFileIcon(ext)} />
							</Logo>
							<Typography sx={{ fontSize: 20 }} color="text.secondary">
								{file.name}
							</Typography>
						</Box>
						{!isUploadMode && (
							<IconButton onClick={() => removeItem(file)}>
								<HighlightOffIcon />
							</IconButton>
						)}
					</Box>
					{isUploadMode && uploadError && <span style={{ color: 'red' }}>Upload Error</span>}
					{isUploadMode && !uploadError && (
						<progress id="file" value={progress} max="100" style={{ width: '100%' }}>
							{progress}%
						</progress>
					)}
				</CardContent>
			</Card>
		</Box>
	);
};

export default FileCard;

export const Logo = styled.div`
	position: relative;
	margin-right: 10px;
`;

export const Img = styled.img``;
