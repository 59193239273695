export type FirstInstallationParameters = {};

export type ValidConfig = {
  endpoint: string;
  accessKeyId: string;
  secretAccessKey: string;
  bucketName: string;
  itemType: string;
  DatoApiKey: string;
  autoApplyToFieldsWithApiKey: string;
  paramsVersion: '2';
};

export type LegacyConfig =
  | {
      shopifyDomain: string;
      storefrontAccessToken: string;
    }
  | FirstInstallationParameters;

export type Config = ValidConfig | LegacyConfig | FirstInstallationParameters;

export function isValidConfig(params: Config): params is ValidConfig {
  return params && 'paramsVersion' in params && params.paramsVersion === '2';
}

export function normalizeConfig(params: Config): ValidConfig {
    if (isValidConfig(params)) {
      return params;
    }
  
    return {
      paramsVersion: '2',
      endpoint: '',
      accessKeyId: '',
      secretAccessKey:  '',
      bucketName: '',
      itemType: '',
      DatoApiKey: '',
      autoApplyToFieldsWithApiKey: '',
    };
  }