import React from 'react';
import { withRouter } from 'react-router-dom';

import { Container, Path } from './styles';
import GoBack from './GoBack';

const showPath = (props, path) => {
	const pathArr = path.split('/').filter((p) => p);
	const len = pathArr.length;

	const x = ['/'];

	const arr = [
		<span key={0} onClick={(e) => e.detail === 2 && props.history.push(x[0])}>{` root `}</span>,
	];

	for (let index = 0; index < pathArr.length; index++) {
		const el = pathArr[index];

		x.push(el + '/');

		if (index === len - 1) {
			arr.push(
				<span
					className="currentPath"
					key={index + 1}
					onClick={(e) => e.detail === 2 && console.log(x.join(''))}
				>
					/ {el}
				</span>,
			);
		} else {
			arr.push(
				<span
					key={index + 1}
					onClick={(e) => e.detail === 2 && console.log(x.join(''))}
				>{` / ${el} `}</span>,
			);
		}
	}

	return arr;
};

const goBack = (path) => {
	const lastIndex = path.lastIndexOf('/');
	let newPath = path.slice(0, lastIndex) + path.slice(lastIndex + 1);
	newPath = newPath.split('/');
	newPath.splice(newPath.length - 1, 1);
	return newPath.join('/') + '/';
};

const Navigation = (props) => {
	return (
		<Container>
			<div
				style={{ marginTop: -2, cursor: 'pointer' }}
				onClick={() =>
					props.location.pathname !== '/' &&
					props.history.push(goBack(props.location.pathname))
				}
			>
				{props.location.pathname !== '/' && <GoBack fill={'#545B61'} />}
			</div>
			<Path>{showPath(props, props.location.pathname)}</Path>
		</Container>
	);
};

export default withRouter(Navigation);
