// import { SiteClient } from 'datocms-client';
import { useMemo, useState, useImperativeHandle, forwardRef } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { buildClient } from '@datocms/cma-client-browser';

import S3Client from '../../../services/s3';
import FileIcon from '../FileIcon';
import PreviewModal from '../PreviewModal';

function Grid({ ctx }, ref) {
	const [openModal, setOpenModal] = useState(false);
	const location = useLocation();
	const {
		endpoint,
		accessKeyId,
		secretAccessKey,
		bucketName,
		DatoApiKey: apiToken,
	} = ctx.plugin.attributes.parameters;

	useImperativeHandle(ref, () => ({
		openModal: (value) => setOpenModal(value),
	}));

	const datoClient = useMemo(() => {
		return buildClient({
			apiToken,
		});
	}, [apiToken]);

	// const datoClient = useMemo(() =>{
	//     return new SiteClient(ctx.currentUserAccessToken, { environment: ctx.environment })
	//     }, [ctx.currentUserAccessToken, ctx.environment],
	// );

	const s3Client = useMemo(() => {
		return new S3Client({ endpoint, accessKeyId, secretAccessKey, bucketName });
	}, [endpoint, accessKeyId, secretAccessKey, bucketName]);

	const {
		data: doFoldersFiles,
		isLoading,
		refetch,
	} = useQuery(
		location.pathname.replace('/', ''),
		async () => {
			const data = await s3Client.getFileListing(location.pathname.replace('/', ''));
			return [...data.folders, ...data.files];
		},
		{ staleTime: 10000 },
	);

	const { data } = useQuery('datoCmsLists', async () => {
		const [docTopics, categories, modules, docCategories] = await Promise.all([
			getDatoData('document_topic', 'name'),
			getDatoData('module_sub_category', 'name'),
			getDatoData('module', 'title'),
			getDatoData('doc_category', 'name'),
		]);

		return {
			docTopics: docTopics.map(({ id, name }) => ({
				id,
				name,
			})),
			categories: categories.map(({ id, name }) => ({
				id,
				name,
			})),
			modules: modules.map(({ id, title }) => ({
				id,
				title,
			})),
			docCategories: docCategories.map(({ id, name }) => ({
				id,
				title: name,
			})),
		};
	});

	function getDatoData(type, orderCol, offset = 0, records = []) {
		const limit = 500;
		const tempRecords = [...records];
		return new Promise(async (resolve) => {
			const newRecords = await datoClient.items.list({
				filter: { type },
				page: {
					offset: limit * offset,
					limit,
				},
				order_by: `${orderCol}_ASC`,
			});

			if (newRecords.length >= 500) {
				//90% chance that it has more than 500 records
				resolve(getDatoData(type, orderCol, offset + 1, newRecords));
			} else {
				const finalRecords =
					tempRecords.length > 0 ? [...tempRecords, ...newRecords] : newRecords;
				resolve(finalRecords);
			}
		});
	}

	return (
		<>
			<Container>
				{isLoading && <span>Loading files...</span>}
				{doFoldersFiles?.map((entry, _) => (
					<FileIcon
						entry={entry}
						index={_}
						key={_}
						// key={`${entry.name}_${entry.type}`}
					/>
				))}
			</Container>
			{openModal && (
				<PreviewModal
					ctx={ctx}
					doFoldersFiles={doFoldersFiles}
					datoClient={datoClient}
					s3Client={s3Client}
					location={location}
					refetch={refetch}
					docTopics={data?.docTopics}
					docCategories={data?.docCategories}
					categories={data?.categories}
					modules={data?.modules}
					handleClose={() => setOpenModal(false)}
				/>
			)}
		</>
	);
}

export default forwardRef(Grid);

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	padding: 40px 0;
`;
