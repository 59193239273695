import Box from '@mui/material/Box';

import FileCard from './FileCard';

const FileList = ({ ctx, newFiles, setNewFiles, isUploading, isUploadMode, handleClose }) => {
	function removeItem(objFile) {
		if (newFiles.length === 1) {
			handleClose();
			return;
		}

		setNewFiles((files) => {
			return files.filter((file) => objFile.name !== file.file.name);
		});
	}

	return (
		<Box>
			{newFiles.map(({ file, progress, uploadError }) => (
				<FileCard
					removeItem={removeItem}
					file={file}
					progress={progress}
					isUploadMode={isUploadMode}
					uploadError={uploadError}
				/>
			))}
		</Box>
	);
};

export default FileList;
