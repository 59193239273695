import { RenderConfigScreenCtx } from 'datocms-plugin-sdk';
import { Button, Canvas, TextField, Form, FieldGroup } from 'datocms-react-ui';
import { Form as FormHandler, Field } from 'react-final-form';
import { ValidConfig } from './types';
import s from './styles.module.css';

type Props = {
  ctx: RenderConfigScreenCtx;
};

export default function ConfigScreen({ ctx }: Props) {
  return (
    <Canvas ctx={ctx}>
      <FormHandler<ValidConfig>
        initialValues={ctx.plugin.attributes.parameters}
        validate={(values: ValidConfig) => {
          const errors: Record<string, string> = {};

          if (!values.endpoint) {
            errors.endpoint = 'This field is required!';
          }

          if (!values.accessKeyId) {
            errors.accessKeyId = 'This field is required!';
          }

          if (!values.secretAccessKey) {
            errors.secretAccessKey = 'This field is required!';
          }

          if (!values.bucketName) {
            errors.bucketName = 'This field is required!';
          }

          if (!values.itemType) {
            errors.itemType = 'This field is required!';
          }
          
          if (!values.DatoApiKey) {
            errors.DatoApiKey = 'This field is required!';
          }

          return errors;
        }}
        onSubmit={async (values: ValidConfig) => {
          await ctx.updatePluginParameters(values);
          ctx.notice('Settings updated successfully!');
        }}
      >
        {({ handleSubmit, submitting, dirty, submitErrors }) => (
          <Form onSubmit={handleSubmit}>
            {submitErrors && submitErrors.tupleFailing && (
              <div className={s.error}>{submitErrors.tupleFailing}</div>
            )}
            <FieldGroup>
              <Field name="endpoint">
                {({ input, meta: { error } }) => (
                  <TextField
                    id="endpoint"
                    label="DO Space Enpoint"
                    required
                    error={error}
                    {...input}
                  />
                )}
              </Field>
              <Field name="accessKeyId">
                {({ input, meta: { error } }) => (
                  <TextField
                    id="accessKeyId"
                    label="Access Key ID"
                    required
                    error={error}
                    textInputProps={{ monospaced: true }}
                    {...input}
                  />
                )}
              </Field>
              <Field name="secretAccessKey">
                {({ input, meta: { error } }) => (
                  <TextField
                    id="secretAccessKey"
                    label="Secret Access Key"
                    textInputProps={{ monospaced: true }}
                    required
                    error={error}
                    {...input}
                  />
                )}
              </Field>
              <Field name="bucketName">
                {({ input, meta: { error } }) => (
                  <TextField
                    id="bucketName"
                    label="Bucket Name"
                    textInputProps={{ monospaced: true }}
                    required
                    error={error}
                    {...input}
                  />
                )}
              </Field>
              <Field name="itemType">
                {({ input, meta: { error } }) => (
                  <TextField
                    id="itemType"
                    label="Document Model ID"
                    textInputProps={{ monospaced: true }}
                    required
                    error={error}
                    {...input}
                  />
                )}
              </Field>
              <Field name="DatoApiKey">
                {({ input, meta: { error } }) => (
                  <TextField
                    id="DatoApiKey"
                    label="Dato API Key"
                    textInputProps={{ monospaced: true }}
                    required
                    error={error}
                    {...input}
                  />
                )}
              </Field>
            </FieldGroup>
            <Button
              type="submit"
              fullWidth
              buttonSize="l"
              buttonType="primary"
              disabled={submitting || !dirty}
            >
              Save settings
            </Button>
          </Form>
        )}
      </FormHandler>
    </Canvas>
  );
}
