import { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { getFileIcon } from '../../../utils';

import FolderIcon from '../../../assets/img/folder.png';

import { Container, Logo, Img, Name } from './styles';

const FILE = '__file__';
const FOLDER = '__folder__';

class Icon extends Component {
	nodeRef = createRef();

	enterFolder = (event) => {
		if (event.detail !== 2) {
			return;
		}

		let replace = this.props.location.pathname.replace('/', '');

		if (this.props.location.pathname !== '/') {
			this.props.entry.type === FOLDER &&
				this.props.history.push(this.props.entry.prefix.replace(replace, ''));
		} else {
			this.props.entry.type === FOLDER && this.props.history.push(this.props.entry.prefix);
		}
	};

	render() {
		const { entry } = this.props;

		let ext = entry.name.split('.').filter((el) => el);
		ext = ext.length >= 2 ? ext[ext.length - 1] : '';

		const icon = entry.type === FILE ? getFileIcon(ext) : FolderIcon;

		return (
			<Container ref={this.nodeRef} onClick={(e) => this.enterFolder(e)}>
				<Logo>
					{entry.type === FILE ? (
						<div
							style={{
								width: '100%',
								height: '100%',
								position: 'absolute',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								color: '#fff',
								fontWeight: 'bold',
							}}
						>
							<div>{`${ext || '?'}`}</div>
						</div>
					) : (
						''
					)}
					<Img src={icon} />
				</Logo>
				<Name>{entry.name}</Name>
			</Container>
		);
	}
}

export default withRouter(Icon);
