import FileIcon from '../assets/img/file.png';
import File1Icon from '../assets/img/file1.png';
import File2Icon from '../assets/img/file2.png';
import File3Icon from '../assets/img/file3.png';
import File4Icon from '../assets/img/file4.png';
import File5Icon from '../assets/img/file5.png';
import File6Icon from '../assets/img/file6.png';
import File7Icon from '../assets/img/file7.png';
import File8Icon from '../assets/img/file8.png';
import File9Icon from '../assets/img/file9.png';
import File10Icon from '../assets/img/file10.png';
import File11Icon from '../assets/img/file11.png';
import File12Icon from '../assets/img/file12.png';
import File13Icon from '../assets/img/file13.png';

export function getFileIcon(ext) {
	let fileIcon;

	const _ext = ext ? ext.toLowerCase() : ext;

	switch (_ext) {
		case 'pdf':
			fileIcon = File9Icon;
			break;
		case 'doc':
		case 'docx':
			fileIcon = File3Icon;
			break;
		case 'csv':
		case 'xls':
		case 'xlsx':
			fileIcon = File2Icon;
			break;
		case 'ppt':
		case 'pptx':
			fileIcon = File11Icon;
			break;
		case 'png':
		case 'jpeg':
		case 'jpg':
			fileIcon = File13Icon;
			break;
		case 'zip':
		case 'rar':
			fileIcon = File8Icon;
			break;
		default:
			fileIcon = FileIcon;
	}

	return fileIcon;
}
