import { useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const focusedStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

const FileDropzone = ({ ctx, doFoldersFiles, setNewFiles }) => {
	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } =
		useDropzone({
			accept: {
				'.pdf': [],
				'.jpeg': [],
				'.jpg': [],
				'.png': [],
				'.ppt': [],
				'.pptx': [],
				'.doc': [],
				'.docx': [],
				'.xls': [],
				'.xlsx': [],
				'.csv': [],
				'.zip': [],
				'.rar': [],
			},
		});

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject],
	);

	useEffect(() => {
		acceptedFiles.length > 0 && onDrop(acceptedFiles);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acceptedFiles]);

	const onDrop = async (droppedFiles) => {
		const doFiles = doFoldersFiles?.filter(({ type }) => type === '__file__');

		const hasDuplicateFile = doFiles?.some((doFile) => {
			return droppedFiles.some((file) => file.name === doFile.name);
		});

		if (hasDuplicateFile) {
			return ctx.alert(
				'You have selected file/s that is/are already existing in the Digital Ocean Spaces',
			);
		}

		setNewFiles(
			droppedFiles.map((file, index) => ({
				file,
				progress: -1,
				title: '',
				module: '',
				categories: [],
				hasPassword: false,
				isFeatured: false,
				freeDocument: false,
				freeSample: false,
				filteUrl: '',
				uploadError: null,
			})),
		);
	};

	return (
		<div className="container">
			<div {...getRootProps({ style })}>
				<input {...getInputProps()} />
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					minHeight="250px"
					width="100%"
				>
					<Typography variant="h6" gutterBottom>
						Drag 'n' drop files here, or click to select files
					</Typography>
				</Box>
			</div>
		</div>
	);
};

export default FileDropzone;
