import { Button, Canvas } from 'datocms-react-ui';
import { useState } from 'react';

import Modal from '../../../components/Modal';
import FileList from '../FileList';
import FileDropzone from '../FileDropZone';
import Form from '../Form';

const PreviewModal = ({
	ctx,
	doFoldersFiles,
	handleClose,
	s3Client,
	location,
	refetch,
	datoClient,
	categories,
	modules,
	docCategories,
	docTopics,
}) => {
	const [newFiles, setNewFiles] = useState([]);
	const [isUploadMode, setIsUploadMode] = useState(false);
	const [isUploading, setIsUploading] = useState(false);

	const { itemType } = ctx.plugin.attributes.parameters;

	const [formData, setFormData] = useState({
		module: '',
		category: [],
		topics: [],
		docCategory: '',
		price: '',
		free_sample: false,
		free_document: false,
		has_password: false,
		is_featured: false,
		is_subscription: false,
	});

	const handleUpload = async (isReupload) => {
		if (!formData?.module) {
			return ctx.alert('Module field is required');
		}

		const result = await ctx.openConfirm({
			title: 'Upload Confirmation',
			content: 'Do you really want to upload the selected Document(s)?',
			choices: [
				{
					label: 'Yes',
					value: 'positive',
					intent: 'positive',
				},
			],
			cancel: {
				label: 'Cancel',
				value: false,
			},
		});

		if (!result) {
			return;
		}

		setNewFiles((files) => {
			return files.map((file) => ({
				...file,
				uploadError: null,
			}));
		});

		let doSpacePath;

		if (location.pathname === '/') {
			doSpacePath = location.pathname.replace('/', '');
		} else {
			const lastIndex = location.pathname.lastIndexOf('/');
			doSpacePath =
				location.pathname.slice(0, lastIndex) + location.pathname.slice(lastIndex + 1);
			doSpacePath = doSpacePath.replace('/', '');
		}

		setIsUploading(true);
		setIsUploadMode(true);

		const promiseArr = [];

		for (let i = 0; i < newFiles.length; i++) {
			if (newFiles[i].progress < 100) {
				promiseArr.push(uploadFilesPromise(i, doSpacePath));
			}
		}

		Promise.allSettled(promiseArr)
			.then((values) => {
				const rejected = values.filter(({ status }) => {
					return status === 'rejected';
				});

				if (rejected.length > 0) {
					// setNewFiles(oldArray => {
					//     return [...oldArray].filter(({uploadError}) => !!uploadError)
					// })

					setIsUploading(false);
				} else {
					handleClose();
				}
			})
			.catch(() => {
				console.log('Error');
			})
			.finally(() => {
				console.log('Done all');
			});
	};

	async function createDocRecord(data) {
		const record = await datoClient.items.create({
			item_type: { type: 'item_type', id: itemType },
			...data,
		});

		await datoClient.items.publish(record.id);

		return record;
	}

	function uploadFilesPromise(i, doSpacePath) {
		return new Promise(async (resolve, reject) => {
			const upload = await s3Client.uploadFiles(newFiles[i].file, doSpacePath, (err, data) => {
				if (err) {
					ctx.alert(`${newFiles[i].file.name} failed to upload`);
					setNewFiles((oldArray) => {
						const newArray = [...oldArray];
						newArray[i].uploadError = err;
						// console.log(newArray)
						return newArray;
					});

					return reject({
						err,
						file: newFiles[i],
					});
				}

				console.log(newFiles[i]);

				const {
					module,
					category: document_category,
					docCategory: doc_category,
					free_sample,
					free_document,
					has_password,
					is_featured,
					is_subscription,
					topics,
					price,
				} = formData;

				const payload = {
					name: newFiles[i].file.name.split('.')[0],
					module,
					document_category,
					file_url: data.Location,
					free_sample,
					free_document,
					has_password,
					is_featured,
					is_subscription,
				};

				if (doc_category) {
					payload.doc_category = doc_category;
				}

				if (topics.length > 0) {
					payload.document_topic = topics;
				}
				if (price) {
					payload.price = price;
				}

				createDocRecord(payload);

				ctx.notice(`${newFiles[i].file.name} successfully uploaded`);

				refetch();
				resolve(data);
			});

			upload.on('httpUploadProgress', (progress) => {
				let progressPercentage = Math.round((progress.loaded / progress.total) * 100);

				setNewFiles((oldArray) => {
					const newArray = [...oldArray];
					newArray[i].progress = progressPercentage;
					return newArray;
				});
			});
		});
	}

	return (
		<Modal
			title={'Upload Document'}
			handleClose={handleClose}
			actionsJsx={
				newFiles.length > 0 && !isUploading ? (
					<ButtonWrapper
						ctx={ctx}
						isUploadMode={isUploadMode}
						onClick={isUploadMode ? handleUpload : handleUpload}
					/>
				) : (
					<></>
				)
			}
		>
			{newFiles.length > 0 ? (
				<>
					{!isUploadMode && (
						<Form
							ctx={ctx}
							formData={formData}
							data={{
								categories,
								docCategories,
								docTopics,
								modules,
							}}
							setFormData={setFormData}
						/>
					)}
					<FileList
						newFiles={newFiles}
						isUploadMode={isUploadMode}
						isUploading={isUploading}
						handleClose={handleClose}
					/>
				</>
			) : (
				<FileDropzone ctx={ctx} doFoldersFiles={doFoldersFiles} setNewFiles={setNewFiles} />
			)}
		</Modal>
	);
};

export default PreviewModal;

const ButtonWrapper = ({ ctx, onClick, isUploadMode }) => {
	return (
		<Canvas ctx={ctx}>
			<Button buttonSize="xxs" buttonType="primary" onClick={onClick}>
				{isUploadMode ? 'Re-upload' : 'Upload'}
			</Button>
		</Canvas>
	);
};
