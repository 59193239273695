import AWS from "aws-sdk";

export default class S3Client {

    endpoint: string;
    accessKeyId: string;
    secretAccessKey:string;
    bucketName:string;
    s3: AWS.S3;
  
    constructor({endpoint, accessKeyId, secretAccessKey, bucketName}) {
        this.endpoint = endpoint;
        this.accessKeyId = accessKeyId;
        this.secretAccessKey = secretAccessKey;
        this.bucketName = bucketName;
        this.s3  = new AWS.S3({ endpoint, accessKeyId, secretAccessKey });
    }

    async getFileListing(prefix = '', bucket = this.bucketName)  {
        const bucketParams = { 
            Bucket: bucket,
            Delimiter: '/',
            Prefix: prefix
        };
    
        return new Promise((resolve, reject) => {

            this.s3.listObjects(bucketParams, (err: any, data: { CommonPrefixes: { Prefix: any; }[]; Contents: any[]; }) => {
                const folders = data?.CommonPrefixes?.map(({Prefix}) => ({
                    name: Prefix?.replace(prefix, "").replace("/", ''),
                    prefix: Prefix,
                    type : '__folder__'
                }));
    
                const files = data?.Contents
                            ?.filter(({Key}) => prefix !== Key)
                            .map(({Key}) => ({
                                name : Key?.replace(prefix, ""),
                                type : '__file__'
                            }));
                
                resolve({ folders, files });
            });
        })
    };

    async uploadFiles(file: { name: string; }, dir: string, callback : Function, bucket = this.bucketName,) {
        const params = { 
            Bucket: bucket, 
            Key: dir ? `${dir}/${file.name}` : `${file.name}`, 
            Body: file ,
          };
    
        const options = {queueSize: 1, partSize: 205242880}

        return this.s3.upload(params, options, function(err: any, data: any) {
            
            if (err) {
            callback(err, data)
              console.log('There was an error uploading your file: ', err);
              return false;
            }
            callback(err, data)
            return true;
        });

    };

    async getSignedUrl (dir: string, file: { name: string; }, bucket : string = this.bucketName) {
        const params = ({
            Bucket: bucket,
            Key: `${dir}/${Date.now()}.${file.name}`, 
            Expires: 60
          })
    
        return new Promise(async (resolve, reject) => {
            const uploadURL = await this.s3.getSignedUrlPromise('putObject', params)
            resolve(uploadURL)
        });
    }
  }