import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Form = ({
	data: { modules, categories, docCategories, docTopics },
	setFormData,
	formData,
}) => {
	return (
		<Box>
			<AutocompleteWrapper
				onChange={(e, val) => {
					const newState = {
						module: val?.id || '',
					};

					if (val?.id !== 113756569) {
						newState['topics'] = [];
					}

					setFormData((s) => ({ ...s, ...newState }));
				}}
				options={modules}
				getOptionLabel={(option) => option.title}
				label="Module"
				placeholder="Select Module"
			/>
			<AutocompleteWrapper
				disableCloseOnSelect={true}
				multiple={true}
				onChange={(e, val) => setFormData((s) => ({ ...s, category: val.map(({ id }) => id) }))}
				options={categories}
				getOptionLabel={(option) => option.name}
				label="Categories"
				placeholder="Select Categories"
			/>
			{/* //Show of Module selected is Topics */}
			{formData.module === 113756569 && (
				<AutocompleteWrapper
					disableCloseOnSelect={true}
					multiple={true}
					onChange={(e, val) =>
						setFormData((s) => ({ ...s, topics: val.map(({ id }) => id) }))
					}
					options={docTopics}
					getOptionLabel={(option) => option.name}
					label="Topics"
					placeholder="Select Topics"
				/>
			)}
			<AutocompleteWrapper
				onChange={(e, val) => setFormData((s) => ({ ...s, docCategory: val?.id || '' }))}
				options={docCategories}
				getOptionLabel={(option) => option.title}
				label="Doc Category"
				placeholder="Select Doc Category"
			/>
			<TextField
				onChange={(e) => {
					const parsedPrice = parseFloat(e.target.value);
					setFormData((s) => ({ ...s, price: isNaN(parsedPrice) ? '' : parsedPrice }));
				}}
				type="number"
				size="small"
				variant="outlined"
				margin="normal"
				InputLabelProps={{ shrink: true }}
				InputProps={{ inputProps: { min: 1 } }}
				label="Price"
				placeholder="Enter Price"
				fullWidth
			/>
			<SwitchesWrapper formData={formData} setFormData={setFormData} />
		</Box>
	);
};

export default Form;

const AutocompleteWrapper = ({
	onChange,
	getOptionLabel,
	options,
	placeholder,
	label,
	disableCloseOnSelect = false,
	multiple = false,
}) => {
	return (
		<Autocomplete
			disableCloseOnSelect={disableCloseOnSelect}
			multiple={multiple}
			style={{ paddingTop: '20px' }}
			onChange={onChange}
			id="tags-standard"
			options={options || []}
			getOptionLabel={getOptionLabel}
			renderInput={(params) => (
				<TextField
					{...params}
					size="small"
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					label={label}
					placeholder={placeholder}
				/>
			)}
		/>
	);
};

const SwitchesWrapper = ({ formData, setFormData }) => {
	return (
		<FormControl component="fieldset" variant="outlined" fullWidth sx={{ marginTop: '20px' }}>
			<FormGroup row sx={{ display: 'flex', justifyContent: 'space-around' }}>
				<FormControlLabel
					control={<Switch name="free_sample" />}
					checked={formData.free_sample}
					label="Free Sample"
					labelPlacement="start"
					onChange={(e) => {
						setFormData((state) => {
							return { ...state, free_sample: e.target.checked };
						});
					}}
				/>
				<FormControlLabel
					control={<Switch name="free_document" />}
					checked={formData.free_document}
					label="Free Document"
					labelPlacement="start"
					onChange={(e) => {
						setFormData((state) => {
							return { ...state, free_document: e.target.checked };
						});
					}}
				/>
				<FormControlLabel
					control={<Switch name="has_password" />}
					checked={formData.has_password}
					label="Has Password"
					labelPlacement="start"
					onChange={(e) => {
						setFormData((state) => {
							return { ...state, has_password: e.target.checked };
						});
					}}
				/>
				<FormControlLabel
					control={<Switch name="is_featured" />}
					checked={formData.is_featured}
					label="Is Featured"
					labelPlacement="start"
					onChange={(e) => {
						setFormData((state) => {
							return { ...state, is_featured: e.target.checked };
						});
					}}
				/>
				<FormControlLabel
					control={<Switch name="is_subscription" />}
					checked={formData.is_subscription}
					label="Is Subscription"
					labelPlacement="start"
					onChange={(e) => {
						setFormData((state) => {
							return { ...state, is_subscription: e.target.checked };
						});
					}}
				/>
			</FormGroup>
		</FormControl>
	);
};
