import { useRef } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Button, Canvas } from 'datocms-react-ui';

import Navigation from '../../components/Navigation';
import Grid from './Grid';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			staleTime: 600000,
		},
	},
});

const ViewFiles = ({ ctx }) => {
	const childRef = useRef(null);

	return (
		<QueryClientProvider client={queryClient}>
			<Container>
				<TopBar>
					<Navigation />
					<Canvas ctx={ctx}>
						<Button
							buttonSize="xxs"
							buttonType="primary"
							onClick={() => {
								childRef.current.openModal(true);
							}}
						>
							Upload Document
						</Button>
					</Canvas>
				</TopBar>
				<Route path="*" component={() => <Grid ctx={ctx} ref={childRef} />} />
			</Container>
		</QueryClientProvider>
	);
};

export default ViewFiles;

const Container = styled.div`
	padding: 41px;
	transition: margin-left 250ms ease-in;
	@media screen and (max-width: 768px) {
		margin-left: 0px;
		padding: 55px 15px 15px 15px;
	}
`;

const TopBar = styled.div`
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: 768px) {
		display: block;
	}
`;
