import { connect, OnBootCtx } from 'datocms-plugin-sdk';

import 'datocms-react-ui/styles.css';
// import Page from './entrypoints/Page';
import Page from './app';
import ConfigScreen from './entrypoints/ConfigScreen';
import { render } from './utils/render';
import { isValidConfig, normalizeConfig } from './entrypoints/types';

const FIELD_EXTENSION_ID = 'docBulkUpload';

connect({
	async onBoot(ctx: OnBootCtx) {
		if (
			!ctx.currentRole.meta.final_permissions.can_edit_schema ||
			isValidConfig(ctx.plugin.attributes.parameters)
		) {
			return;
		}

		const fields = await ctx.loadFieldsUsingPlugin();

		const someUpgraded = (
			await Promise.all(
				fields.map(async (field) => {
					if (
						field.attributes.appearance.editor !== ctx.plugin.id ||
						field.attributes.appearance.field_extension === FIELD_EXTENSION_ID
					) {
						return false;
					}

					await ctx.updateFieldAppearance(field.id, [
						{
							operation: 'updateEditor',
							newFieldExtensionId: FIELD_EXTENSION_ID,
						},
					]);

					return true;
				}),
			)
		).some((x) => x);

		await ctx.updatePluginParameters(normalizeConfig(ctx.plugin.attributes.parameters));

		if (someUpgraded) {
			ctx.notice('Plugin upgraded successfully!');
		}
	},
	mainNavigationTabs(ctx) {
		return [
			{
				label: 'Docs Bulk Upload',
				// label: 'Docs Bulk Upload[Dev]',
				icon: 'upload',
				pointsTo: {
					pageId: 'docs-bulk-upload',
					// pageId: 'docs-bulk-upload-dev',
				},
			},
		];
	},
	renderPage(pageId, ctx) {
		return render(<Page ctx={ctx} />);
	},
	renderConfigScreen(ctx) {
		return render(<ConfigScreen ctx={ctx} />);
	},
});
