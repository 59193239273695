import CloseIcon from '@mui/icons-material/Close';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
// import { Button, Canvas } from 'datocms-react-ui';

const Dialog = styled(MuiDialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function DialogTitle(props) {
	const { children, onClose, ...other } = props;

	return (
		<MuiDialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
}

const Modal = ({ title, handleClose, children, actionsJsx }) => {
	return (
		<Dialog
			onClose={(e, reason) => {
				if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
					return;
				}
				handleClose();
			}}
			aria-labelledby="customized-dialog-title"
			open
			fullWidth
			maxWidth="md"
		>
			<DialogTitle id="customized-dialog-title" onClose={handleClose}>
				{title}
			</DialogTitle>
			<MuiDialogContent dividers>{children}</MuiDialogContent>
			<MuiDialogActions>{actionsJsx}</MuiDialogActions>
		</Dialog>
	);
};

export default Modal;
