import { BrowserRouter as Router } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

import './assets/styles/App.scss';
import { FilesViewer } from './pages';

const App = ({ctx}) => (
    <Router>
      <BrowserRouter>
          <FilesViewer ctx={ctx} />
        </BrowserRouter>
    </Router>
);

export default App;
